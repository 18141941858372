window.addEventListener("DOMContentLoaded", () => {
  fabric.Object.prototype.set({
    transparentCorners: false,
    cornerColor: "rgba(102,153,255,0.5)",
    padding: 0,
  });

  // window.canvas = window._canvas = new fabric.Canvas('canvas');
  // window.loadSVG = function(id) {
  // 	canvas.clear();
  // 	var elem = document.getElementById(id),
  // svgStr = elem.innerHTML;

  // fabric.loadSVGFromString(svgStr, function(objects, options) {
  // canvas.add.apply(canvas, objects);
  // canvas.renderAll();
  // });
  // }

  // window.fillColor = function(color) {
  // 	canvas.getActiveObject().set("fill", color);
  // canvas.renderAll();
  // }
});
