window.jQuery = $;
window.$ = $;

window.bootstrap = require('bootstrap');

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

import $ from "jquery";
require("bootstrap");
require("@popperjs/core");

import { fabric } from "fabric";

import "./plugin/canvas-event.js";
import "./plugin/animation.js";
import "./plugin/color-picker.js";
import "./plugin/filesave.js";
import "@fortawesome/fontawesome-free/js/all"
import "./plugin/password.js"
import flatpickr from "flatpickr"
import 'select2';                       
import 'select2/dist/css/select2.css';
import "./plugin/bootstrap.bundle";
import Sortable from 'sortablejs'


window.Sortable = Sortable;
window.Rails = Rails;

require("flatpickr")
require("@nathanvda/cocoon")
require("flatpickr/dist/plugins/monthSelect/style.css")
require("flatpickr/dist/flatpickr.css")
require("sortablejs")
global.weekSelect = require("flatpickr/dist/plugins/weekSelect/weekSelect");
global.monthSelectPlugin = require("flatpickr/dist/plugins/monthSelect/index");
global.convert = require('color-convert');

Rails.start();
Turbolinks.start();
ActiveStorage.start();

document.addEventListener("turbolinks:load", () => {
  flatpickr("[data-behavior='flatpickr']", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    altInputClass: "form-control input flat",
  }),
  setTimeout(function() {
    $('.success, .alert').fadeOut();
  }, 10000); 
})

